import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FiTruck } from 'react-icons/fi';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import {
  createAjusteEstoque,
  getAjusteEstoque,
  updateAjusteEstoque,
} from 'services/api/ajusteEstoque';

import { getProdutos } from 'services/api/produto';

import { useToast } from 'contexts';

import * as C from 'components';

import { tipoEstoqueOptions } from './options';

import * as S from './styles';

import { IAjusteEstoque, IAjusteEstoqueForm } from '../interfaces';

const schema = Yup.object().shape({
  tipo: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Tipo obrigatorio')
    .typeError('Tipo obrigatorio'),
  produto_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Produto obrigatorio')
    .typeError('Produto obrigatorio'),
  quantidade: Yup.string()
    .typeError('Quantidade é obrigatório')
    .required('Quantidade é obrigatório'),
});

export const EnvioAjusteEstoqueForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setError,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      tipo: { label: 'Entrada', value: 'E' },
    },
  });

  const [loading, setLoading] = useState(false);
  const [ajuste, setAjuste] = useState<IAjusteEstoque>();

  const loadOptionsProdutos = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getProdutos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
            preco: item.preco_custo_costureira,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  useEffect(() => {
    if (id) {
      getAjusteEstoque(id).then((data) => {
        setAjuste(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IAjusteEstoqueForm) => {
      try {
        setLoading(true);

        if (id) {
          if (!data.produto_id?.value) {
            setError('produto_id', {
              message: 'Produto obrigatorio',
            });

            return;
          }

          if (data.quantidade === '0,00') {
            setError('quantidade', {
              message: 'Quantidade é obrigatório',
            });

            return;
          }

          const response = await updateAjusteEstoque(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/ajuste-estoque');
          }
        } else {
          if (!data.produto_id?.value) {
            setError('produto_id', {
              message: 'Produto obrigatorio',
            });

            return;
          }

          if (data.quantidade === '0,00') {
            setError('quantidade', {
              message: 'Quantidade é obrigatório',
            });

            return;
          }

          await createAjusteEstoque(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/ajuste-estoque');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset, setError],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/ajuste-estoque">
              <MdKeyboardBackspace size={24} color="#fff" />
              Ajuste Estoque
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id
                ? `Editando ajuste produto: ${ajuste?.produto.nome}`
                : 'Novo ajuste'
            }
            icon={FiTruck}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          {id ? (
            <C.FormGroup>
              <C.Label>Tipo Estoque</C.Label>
              <C.ReactSelect
                {...register('tipo')}
                name="tipo"
                control={control}
                errors={errors.tipo}
                isDisabled
              />
            </C.FormGroup>
          ) : (
            <C.FormGroup>
              <C.Label>Tipo Estoque</C.Label>
              <C.ReactSelect
                {...register('tipo')}
                name="tipo"
                control={control}
                errors={errors.tipo}
                options={tipoEstoqueOptions}
                noOptionsMessage="Nenhum tipo de estoque localizado"
              />
            </C.FormGroup>
          )}

          {id ? (
            <C.FormGroup>
              <C.Label>Produto</C.Label>
              <C.ReactSelectAsync
                {...register('produto_id')}
                control={control}
                errors={errors.produto_id}
                isDisabled
              />
            </C.FormGroup>
          ) : (
            <C.FormGroup>
              <C.Label>Produto</C.Label>
              <C.ReactSelectAsync
                {...register('produto_id')}
                control={control}
                errors={errors.produto_id}
                loadOptions={loadOptionsProdutos}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum produto localizado"
              />
            </C.FormGroup>
          )}

          <C.FormGroup>
            <C.Label>Quantidade</C.Label>
            <C.InputCurrency
              {...register('quantidade')}
              errors={errors.quantidade}
              name="quantidade"
              placeholder="Ex: 50"
            />
          </C.FormGroup>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
