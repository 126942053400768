import { IPaginate, IParams } from 'shared/interfaces';
import { IFornecedor, IFornecedorForm } from 'pages/Fornecedor/interfaces';

import { maskCep, maskCpf, maskCnpj } from 'utils/masks';
import api from '../../api';

const getFornecedor = async (id: string): Promise<IFornecedor> => {
  const response = await api.get<IFornecedor>(`/fornecedores/${id}`);

  const fornecedorFormatado = {
    ...response.data,
    tipo_pessoa: response.data.tipo_pessoa
      ? {
          label:
            String(
              response.data?.tipo_pessoa === 'F' ? 'Física' : 'Jurídica',
            ) || '',
          value: String(response.data?.tipo_pessoa) || '',
        }
      : '',
    tipo_fornecedor: response.data.tipo_fornecedor
      ? {
          label:
            String(
              response.data?.tipo_fornecedor === 'F'
                ? 'Fornecedor'
                : 'Costureira',
            ) || '',
          value: String(response.data?.tipo_fornecedor) || '',
        }
      : '',
    cep: maskCep('', response.data.cep),
    cnpj:
      response.data.tipo_pessoa === 'F'
        ? maskCpf('', response.data.cnpj)
        : maskCnpj('', response.data.cnpj),
    centro_custo_id: response.data?.centro_custo?.id
      ? {
          label: String(response.data?.centro_custo?.nome) || '',
          value: String(response.data?.centro_custo?.id) || '',
        }
      : '',
    plano_contas_id: response.data?.plano_contas?.id
      ? {
          label: String(response.data?.plano_contas?.nome) || '',
          value: String(response.data?.plano_contas?.id) || '',
        }
      : '',
    forma_pagamento_id: response.data?.forma_pagamento?.id
      ? {
          label: String(response.data?.forma_pagamento?.nome) || '',
          value: String(response.data?.forma_pagamento?.id) || '',
        }
      : '',
    conta_id: response.data?.conta?.id
      ? {
          label: String(response.data?.conta?.nome) || '',
          value: String(response.data?.conta?.id) || '',
        }
      : '',
  };

  return fornecedorFormatado;
};

const getFornecedores = async (
  params: IParams,
): Promise<IPaginate<IFornecedor>> => {
  const response = await api.get<IPaginate<IFornecedor>>('/fornecedores', {
    params,
  });

  return response.data;
};

const getAllFornecedores = async (
  params: IParams,
): Promise<IPaginate<IFornecedor>> => {
  const response = await api.get<IPaginate<IFornecedor>>(
    '/fornecedores/list/all',
    {
      params,
    },
  );

  const fornecedorFormatado = response.data.data.map((item: IFornecedor) => {
    return {
      ...item,
      tipo_fornecedor:
        item.tipo_fornecedor === 'C' ? 'COSTUREIRA' : 'FORNECEDOR',
    };
  });

  return {
    ...response.data,
    data: fornecedorFormatado,
  };
};

const getCostureiras = async (
  params: IParams,
): Promise<IPaginate<IFornecedor>> => {
  const response = await api.get<IPaginate<IFornecedor>>('/costureiras', {
    params,
  });

  return response.data;
};

const createFornecedor = async (fornecedor: IFornecedorForm): Promise<void> => {
  console.log(fornecedor);

  const data = {
    ...fornecedor,
    tipo_pessoa: fornecedor.tipo_pessoa.value,
    tipo_fornecedor: fornecedor.tipo_fornecedor.value,
    // cep: fornecedor.cep.replace(/\D/g, ''),
    // cnpj: fornecedor.cnpj.replace(/\D/g, ''),
    centro_custo_id: fornecedor.centro_custo_id
      ? fornecedor.centro_custo_id.value
      : null,
    plano_contas_id: fornecedor.plano_contas_id
      ? fornecedor.plano_contas_id.value
      : null,

    forma_pagamento_id: fornecedor.forma_pagamento_id
      ? fornecedor.forma_pagamento_id.value
      : null,

    conta_id: fornecedor.conta_id ? fornecedor.conta_id.value : null,
  };

  await api.post('/fornecedores', data);
};

const updateFornecedor = async (
  id: string,
  fornecedor: IFornecedorForm,
): Promise<IFornecedor> => {
  const data = {
    ...fornecedor,
    tipo_pessoa: fornecedor.tipo_pessoa.value,
    tipo_fornecedor: fornecedor.tipo_fornecedor.value,
    // cep: fornecedor.cep.replace(/\D/g, ''),
    // cnpj: fornecedor.cnpj.replace(/\D/g, ''),
    centro_custo_id: fornecedor.centro_custo_id
      ? fornecedor.centro_custo_id.value
      : null,
    plano_contas_id: fornecedor.plano_contas_id
      ? fornecedor.plano_contas_id.value
      : null,
    forma_pagamento_id: fornecedor.forma_pagamento_id
      ? fornecedor.forma_pagamento_id.value
      : null,

    conta_id: fornecedor.conta_id ? fornecedor.conta_id.value : null,
  };

  delete data.centro_custo;
  delete data.plano_contas;
  delete data.forma_pagamento;
  delete data.conta;

  const response = await api.put<IFornecedor>(`/fornecedores/${id}`, data);

  return response.data;
};

const deleteFornecedor = async (id: string): Promise<void> => {
  await api.delete(`/fornecedores/${id}`);
};

export {
  getFornecedor,
  getFornecedores,
  getAllFornecedores,
  getCostureiras,
  createFornecedor,
  updateFornecedor,
  deleteFornecedor,
};
