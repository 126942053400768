import React, { useState, useEffect, useCallback } from 'react';

import { MdSearch } from 'react-icons/md';
import { IPaginate } from 'shared/interfaces';
import { useToast } from 'contexts';

import { getConsolidado } from 'services/api/contasReceberRelatorios';

import * as C from 'components';
import * as S from './styles';

import { IRelatorioReceberConsolidado } from '../interfaces';

export const ReceberConsolidado: React.FC = () => {
  const { addToast } = useToast();
  const [titulos, setTitulos] =
    useState<IPaginate<IRelatorioReceberConsolidado>>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');

  const handleSearch = useCallback(
    ({ page, per_page }) => {
      setLoading(true);

      const params = {
        page,
        per_page,
        term,
      };

      getConsolidado(params)
        .then((data) => {
          setTitulos(data);

          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });

          setLoading(false);
        });
    },
    [addToast, term],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
    };

    handleSearch(params);
  }, [handleSearch]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <C.HeaderPage>
          <S.ContainerSearch>
            <C.InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter'
                  ? handleSearch({
                      page: 1,
                      per_page: titulos?.per_page,
                    })
                  : {};
              }}
            />
          </S.ContainerSearch>
        </C.HeaderPage>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th className="text-left">Cliente</th>
                    <th className="text-center">Valor Venda</th>
                    <th className="text-center">Valor Juros</th>
                    <th className="text-center">Valor Acréscimo</th>
                    <th className="text-center">Valor Desconto</th>
                    <th className="text-center">Valor Pago</th>
                    <th className="text-center">Valor Saldo</th>
                  </tr>
                </thead>
                <tbody>
                  {titulos &&
                    titulos.data.map((item: IRelatorioReceberConsolidado) => (
                      <tr key={item.id}>
                        <td>{item.nome}</td>
                        <td className="text-right">{item.valor_titulo}</td>
                        <td className="text-right">{item.valor_juros}</td>
                        <td className="text-right">{item.valor_acrescimo}</td>
                        <td className="text-right">{item.valor_desconto}</td>
                        <td className="text-right">{item.valor_pago}</td>
                        <S.Saldo isDebit={item.valor_saldo?.includes('-R$')}>
                          {item.valor_saldo}
                        </S.Saldo>
                      </tr>
                    ))}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {titulos && (
            <C.Pagination
              page={titulos?.page}
              total={titulos?.total}
              per_page={titulos?.per_page}
              last_page={titulos?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>
    </S.Container>
  );
};
