import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import {
  IAjusteEstoque,
  IAjusteEstoqueForm,
} from 'pages/AjusteEstoque/interfaces';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createAjusteEstoqueData = (ajuste: IAjusteEstoqueForm) => {
  const data = {
    ...ajuste,
    produto_id: ajuste.produto_id.value,
    tipo: ajuste.tipo.value,
    quantidade: formatCurrencyDataBase(ajuste.quantidade),
  };

  return data;
};

const getAjusteEstoque = async (id: string): Promise<IAjusteEstoque> => {
  const { data } = await api.get<IAjusteEstoque>(`ajustes-estoque/${id}`);

  const ajusteFormatadado = {
    ...data,
    quantidade: formatCurrencyBR(Number(data.quantidade), false),
    tipo: data?.tipo
      ? {
          label: data.tipo === 'E' ? 'Entrada' : 'Saída',
          value: String(data?.tipo) || '',
        }
      : '',
    produto_id: data?.produto?.id
      ? {
          label: String(data?.produto?.nome) || '',
          value: String(data?.produto?.id) || '',
        }
      : '',
  };

  return ajusteFormatadado;
};

const getAjustesEstoque = async (
  params: IParams,
): Promise<IPaginate<IAjusteEstoque>> => {
  const response = await api.get<IPaginate<IAjusteEstoque>>('ajustes-estoque', {
    params,
  });

  const ajustesFormatado = response.data.data.map((item: IAjusteEstoque) => {
    return {
      ...item,
      tipo: item.tipo === 'S' ? 'SAIDA' : 'ENTRADA',
      quantidade: formatCurrencyBR(Number(item.quantidade), false),
      created_at: item.created_at
        ? toFormatDate(new Date(item.created_at), CustomFormats.dd_MM_yyyy)
        : '',
    };
  });

  return {
    ...response.data,
    data: ajustesFormatado,
  };
};

const createAjusteEstoque = async (
  ajuste: IAjusteEstoqueForm,
): Promise<void> => {
  const data = createAjusteEstoqueData(ajuste);

  await api.post('ajustes-estoque', data);
};

const updateAjusteEstoque = async (
  id: string,
  ajuste: IAjusteEstoqueForm,
): Promise<IAjusteEstoque> => {
  const data = createAjusteEstoqueData(ajuste);

  const response = await api.put<IAjusteEstoque>(`ajustes-estoque/${id}`, data);

  return response.data;
};

const deleteAjusteEstoque = async (id: string): Promise<void> => {
  await api.delete(`ajustes-estoque/${id}`);
};

export {
  getAjusteEstoque,
  getAjustesEstoque,
  createAjusteEstoque,
  updateAjusteEstoque,
  deleteAjusteEstoque,
};
