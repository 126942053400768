import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';

import * as P from '../pages';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Redirect path="/" to="/signin" exact />
      <Route path="/signin" component={P.SignIn} />
      <Route path="/signup" component={P.SignUp} />
      {/* <Route path="/forgot-password" component={P.ForgotPassword} /> */}
      {/* <Route path="/reset-password" component={P.ResetPassword} /> */}

      <Route path="/formtest" component={P.FormTest} isPrivate exact />
      <Route path="/formtest/:id" component={P.FormTest} isPrivate exact />
      <Route path="/dashboard" component={P.Dashboard} isPrivate exact />

      <Route path="/permissions" component={P.Permission} isPrivate />

      <Route path="/profile" component={P.Profile} isPrivate />

      <Route path="/users" component={P.User} isPrivate />

      <Route path="/permissions" component={P.Permission} isPrivate />

      <Route path="/clientes" component={P.Cliente} isPrivate />
      <Route path="/fornecedores" component={P.Fornecedor} isPrivate />
      <Route path="/produtos" component={P.Produto} isPrivate />
      <Route path="/produto/familias" component={P.Familia} isPrivate />
      <Route path="/produto/cores" component={P.Cor} isPrivate />
      <Route path="/contas" component={P.Conta} isPrivate />
      <Route path="/formas-pagamento" component={P.FormaPagamento} isPrivate />
      <Route path="/centro-custo" component={P.CentroCusto} isPrivate />
      <Route path="/plano-contas-pai" component={P.PlanoContasPai} isPrivate />
      <Route path="/plano-contas" component={P.PlanoContas} isPrivate />

      <Route path="/contas-pagar" component={P.ContasPagar} isPrivate />
      <Route
        path="/baixas/contas-pagar"
        component={P.ContasPagarBaixas}
        isPrivate
      />
      <Route
        path="/relatorios/contas-pagar"
        component={P.ContasPagarRelatorios}
        isPrivate
      />

      <Route
        path="/contas-pagar-costureira"
        component={P.ContasPagarCostureira}
        isPrivate
      />
      <Route
        path="/baixas/contas-pagar-costureira"
        component={P.ContasPagarCostureiraBaixas}
        isPrivate
      />
      <Route
        path="/relatorios/contas-pagar-costureira"
        component={P.ContasPagarCostureiraRelatorios}
        isPrivate
      />

      <Route path="/contas-receber" component={P.ContasReceber} isPrivate />
      <Route
        path="/baixas/contas-receber"
        component={P.ContasReceberBaixas}
        isPrivate
      />
      <Route
        path="/relatorios/contas-receber"
        component={P.ContasReceberRelatorios}
        isPrivate
      />

      <Route path="/envio-corte" component={P.EnvioCorte} isPrivate />
      <Route
        path="/recebimento-corte"
        component={P.RecebimentoCorte}
        isPrivate
      />

      <Route path="/ajuste-estoque" component={P.AjusteEstoque} isPrivate />

      <Route path="/vendas" component={P.Vendas} isPrivate />
      <Route path="/mobile/vendas" component={P.VendasMobile} isPrivate />

      <Route path="/analytics" component={P.Analytics} isPrivate />
    </Switch>
  );
};

export default Routes;
