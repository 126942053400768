import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import * as Yup from 'yup';

import { resetPassword } from 'services/api/ResetPassword';
import { useToast } from 'contexts';

import * as C from 'components';
import logoImg from 'assets/logo.png';
import * as S from './styles';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const schema = Yup.object().shape({
  password: Yup.string().required('Senha obrigatória'),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), undefined],
    'Confirmação incorreta',
  ),
});

export const ResetPassword: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        const { password, password_confirmation } = data;
        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error('Token not found');
        }

        await resetPassword({
          password,
          password_confirmation,
          token,
        });

        history.push('/');
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro ao resetar senha',
          description: 'Ocorreu um erro ao resetar sua senha, tente novamente',
        });
      }
    },
    [addToast, history, location],
  );

  return (
    <S.Container>
      <S.Content>
        <S.AnimationContainer>
          <img src={logoImg} alt="Mulher Elegante" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Resetar senha</h1>
            <C.Input
              type="password"
              {...register('password')}
              errors={errors.password}
              name="password"
              icon={FiLock}
              placeholder="Nova Senha"
              autoComplete="off"
            />
            <C.Input
              type="password"
              {...register('password_confirmation')}
              errors={errors.password_confirmation}
              name="password_confirmation"
              icon={FiLock}
              placeholder="Confirmação da senha"
              autoComplete="off"
            />

            <C.Button type="submit">Alterar senha</C.Button>
          </form>
        </S.AnimationContainer>
      </S.Content>
      <S.Background />
    </S.Container>
  );
};
