import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiMail, FiLock } from 'react-icons/fi';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useAuth, useToast } from 'contexts';

import * as C from 'components';
import logoImg from 'assets/logo.png';
import * as S from './styles';

interface SignInFormData {
  email: string;
  password: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  password: Yup.string().required('Senha obrigatória'),
});

export const SignIn: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>({
    resolver: yupResolver(schema),
  });

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const onSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro na autenricação',
          description:
            'Ocorreu um erro ao fazer login, verifique as credenciais EMAIL / SENHA',
        });
      }
    },
    [signIn, addToast],
  );

  return (
    <S.Container>
      <S.Content>
        <S.AnimationContainer>
          <img src={logoImg} alt="Mulher Elegante" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Faça seu logon</h1>

            <C.Input
              type="text"
              {...register('email')}
              name="email"
              icon={FiMail}
              placeholder="E-mail"
              errors={errors.email}
            />
            <C.Input
              type="password"
              {...register('password')}
              name="password"
              icon={FiLock}
              placeholder="Senha"
              autoComplete="off"
              errors={errors.email}
            />

            <C.Button variant="primary" type="submit">
              Entrar
            </C.Button>

            <Link to="/forgot-password">Esqueci minha senha</Link>
          </form>
        </S.AnimationContainer>
      </S.Content>
      <S.Background />
    </S.Container>
  );
};
