import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { useHistory, Link } from 'react-router-dom';
import { FaMoneyBill } from 'react-icons/fa';
import { MdSearch } from 'react-icons/md';

import { IPaginate } from 'shared/interfaces';
import { useToast } from 'contexts';
import { ModalHandles } from 'components/Modal';

import {
  getBaixas,
  deleteBaixa,
  statusComprovante,
} from 'services/api/contasPagarBaixas';

import * as C from 'components';
import * as S from './styles';

import { IContasPagarBaixas } from '../interfaces';

export const ContasPagarBaixasList: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [titulos, setTitulos] = useState<IPaginate<IContasPagarBaixas>>();
  const [tituloAtual, setTituloAtual] = useState<IContasPagarBaixas>();
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState('');
  const modalRef = useRef<ModalHandles>(null);
  const [modalType, setModalType] = useState('remove');

  const handleSearch = useCallback(
    ({ page, per_page }) => {
      setLoading(true);

      const params = {
        page,
        per_page,
        term,
      };

      getBaixas(params)
        .then((data) => {
          setTitulos(data);

          setLoading(false);
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'Ops, Erro',
            description: e.response?.data?.message,
          });

          setLoading(false);
        });
    },
    [addToast, term],
  );

  useEffect(() => {
    const params = {
      page: 1,
      per_page: 20,
    };

    handleSearch(params);
  }, [handleSearch]);

  const handleRemove = useCallback(async () => {
    try {
      await deleteBaixa({ id: tituloAtual!.id });

      if (!titulos) return;

      const newList = titulos.data.filter(
        (item) => item.id !== tituloAtual?.id,
      );
      setTitulos({ ...titulos, data: newList });

      modalRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Registro removido com sucesso',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao remover o registro',
      });
    }
  }, [addToast, tituloAtual, titulos]);

  const handleOpenModal = useCallback(
    (tituloSelected: IContasPagarBaixas, type: string): void => {
      setModalType(type);

      setTituloAtual(tituloSelected);

      modalRef.current?.openModal();
    },
    [],
  );

  const handleChangeStatusComprovante = useCallback(async () => {
    try {
      await statusComprovante(tituloAtual!.id);

      if (!titulos) return;

      const newList = titulos.data.map((item) => {
        if (item.id === tituloAtual?.id) {
          return { ...item, envio_comprovante: 'S' };
        }

        return item;
      });

      setTitulos({ ...titulos, data: newList });

      modalRef.current?.closeModal();

      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Status do comprovante modificado com sucesso!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ops, Erro',
        description: 'Ocorreu um erro ao modificar o status',
      });
    }
  }, [addToast, tituloAtual, titulos]);

  const renderModal = useMemo(() => {
    if (modalType === 'remove') {
      return (
        <C.Modal ref={modalRef} options={{ width: '400px' }}>
          <C.ModalHeader>
            <C.ModalTitle>Você Confirma a EXCLUSÃO?</C.ModalTitle>
          </C.ModalHeader>
          <C.ModalBody>
            <p>O registro será removido completamente!</p>
          </C.ModalBody>
          <C.ModalFooter>
            <C.Position align="right">
              <C.Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </C.Button>
              <C.Button
                variant="primary"
                onClick={() => handleRemove()}
                style={{ marginLeft: '16px' }}
              >
                SIM, confirmo a exclusão
              </C.Button>
            </C.Position>
          </C.ModalFooter>
        </C.Modal>
      );
    }

    if (modalType === 'status') {
      return (
        <C.Modal ref={modalRef} options={{ width: '400px' }}>
          <C.ModalHeader>
            <C.ModalTitle>Confirma envio do comprovante?</C.ModalTitle>
          </C.ModalHeader>
          <C.ModalBody>
            <p>O Título será marcado como enviado o comprovante!</p>
          </C.ModalBody>
          <C.ModalFooter>
            <C.Position align="right">
              <C.Button
                variant="outline-secondary"
                onClick={() => modalRef.current?.closeModal()}
              >
                Cancelar
              </C.Button>
              <C.Button
                variant="primary"
                onClick={() => handleChangeStatusComprovante()}
                style={{ marginLeft: '16px' }}
              >
                SIM, confirmo a mudança
              </C.Button>
            </C.Position>
          </C.ModalFooter>
        </C.Modal>
      );
    }

    return <></>;
  }, [handleChangeStatusComprovante, handleRemove, modalType]);

  return (
    <S.Container>
      <C.HeaderBackground>
        <C.HeaderPage>
          <C.HeaderInfo title="Pagamentos de títulos" icon={FaMoneyBill} />

          <S.ContainerSearch>
            <C.InputSearch
              title="Digite aqui o que deseja pesquisar"
              icon={MdSearch}
              placeholder="Pesquisar"
              onChange={(e) => setTerm(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter'
                  ? handleSearch({
                      page: 1,
                      per_page: titulos?.per_page,
                    })
                  : {};
              }}
            />
          </S.ContainerSearch>

          <S.HeaderButtonContainer>
            <Link to="/baixas/contas-pagar/print">
              <S.IconPrint />
            </Link>

            <C.Can checkPermission="contas_pagar_baixas_create">
              <C.ButtonLink
                title="Adicionar Titulo"
                to="/baixas/contas-pagar/new"
              >
                Adicionar
              </C.ButtonLink>
            </C.Can>
          </S.HeaderButtonContainer>
        </C.HeaderPage>
      </C.HeaderBackground>

      <S.Panel>
        {loading ? (
          <C.Loader loading={loading} align="center" message="Carregando..." />
        ) : (
          <>
            <div className="tbl-content">
              <C.Table>
                <thead>
                  <tr>
                    <th className="text-center">Comprovante</th>
                    <th className="text-left">Fornecedor</th>
                    <th className="text-left">Cliente</th>
                    <th className="text-left">Forma</th>
                    <th className="text-left">Centro Custo</th>
                    <th className="text-left">Plano Contas</th>
                    <th className="text-left">Conta</th>
                    <th className="text-center">Data Pagamento</th>
                    <th className="text-center">Hora Pagamento</th>
                    <th className="text-center">Valor Título</th>
                    <th className="text-center">Valor Pago</th>
                    <th className="text-center">Valor Juros</th>
                    {/* <th className="text-center">Valor Acréscimo</th>
                    <th className="text-center">Valor Desconto</th> */}
                    <th className="text-center">Obs</th>
                    <th className="text-center">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {titulos &&
                    titulos.data.map((item: IContasPagarBaixas) => (
                      <tr key={item.id}>
                        <td className="text-center">
                          <C.Status
                            enable={String(item.envio_comprovante === 'S')}
                          />
                        </td>
                        <td>{item.fornecedor.nome}</td>
                        <td>{item.cliente?.nome}</td>
                        <td>{item.forma_pagamento?.nome}</td>
                        <td>{item.centro_custo?.nome}</td>
                        <td>{item.plano_contas?.nome}</td>
                        <td>{item.conta.nome}</td>
                        <td className="text-center">{item.data_pagamento}</td>
                        <td className="text-center">{item.hora_pagamento}</td>
                        <td className="text-center">{item.valor_titulo}</td>
                        <td className="text-center">{item.valor_pago}</td>
                        <td className="text-center">{item.valor_juros}</td>
                        {/* <td className="text-right">{item.valor_acrescimo}</td>
                        <td className="text-right">{item.valor_desconto}</td> */}
                        <td className="text-right">{item.observacao}</td>
                        <td className="text-center">
                          <C.PopUpMenu>
                            <C.Can checkPermission="contas_pagar_baixas_update">
                              <button
                                type="button"
                                onClick={() =>
                                  history.push(
                                    `/baixas/contas-pagar/edit/${item.id}`,
                                  )
                                }
                              >
                                <S.IconEdit
                                  title="Editar"
                                  size={24}
                                  color="#707070"
                                />
                                Editar
                              </button>
                            </C.Can>

                            <C.Can checkPermission="contas_pagar_baixas_delete">
                              <button
                                type="button"
                                onClick={() => handleOpenModal(item, 'remove')}
                              >
                                <S.IconDelete
                                  title="Remover"
                                  size={24}
                                  color="#707070"
                                />
                                Remover
                              </button>
                            </C.Can>

                            <button
                              type="button"
                              onClick={() => handleOpenModal(item, 'status')}
                            >
                              <S.IconStatus
                                title="Comprovante"
                                size={24}
                                color="#707070"
                              />
                              Comprovante
                            </button>
                          </C.PopUpMenu>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </C.Table>
            </div>
          </>
        )}
        <S.PaginationContainer>
          {titulos && (
            <C.Pagination
              page={titulos?.page}
              total={titulos?.total}
              per_page={titulos?.per_page}
              last_page={titulos?.last_page}
              onSearch={handleSearch}
            />
          )}
        </S.PaginationContainer>
      </S.Panel>

      {renderModal}
    </S.Container>
  );
};
